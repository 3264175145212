@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

pre {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

/***********   common css **************/
.spaceupdown {
  margin: 2.5em 0;
}

.spacebottom {
  margin-bottom: 50px;
}
.spacepadding {
  padding-bottom: 40px;
}

.commonbg {
  background-image: url("../src/assets/Aboutus/commonbgpage.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5em 0;
  height: 100%;
  display: grid;
  place-content: center;
  min-height: 350px;
  position: relative;
}

.commonbg .dark-overlay {
  display: flex;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.35);
  height: 100%;
}

.feulaskeri {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2em 0;
  min-height: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*********** top menu css ***********/

section.TopMenu {
  background-color: #1c3a27;
  color: #fff;
  padding: 10px 0px;
}
.infotopmenulinks ul {
  display: flex;
  margin-bottom: 0px;

  padding-left: 0px;
}

.infotopmenulinks ul li,
.topmenusociallinks ul li {
  margin-right: 30px;
}

.infotopmenulinks ul li i {
  margin-right: 10px;
  font-size: 20px;
}
.infotopmenulinks ul li a {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.topmenusociallinks ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}

.topmenusociallinks ul li i {
  font-size: 20px;
}

.nav-link,
.navbar-toggler-icon {
  color: #fff;
}

.navbar-toggler {
  border-color: #fff !important;
}

.navmenu-link {
  color: #fff;
  margin: 0px 13px;
  position: relative;
}

.navmenu-link:hover {
  color: #fff;
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-decoration-color: #fff;
}

.bod-menu {
  position: relative !important;
}

.bod-menu .dropdown-toggle {
  color: #fff !important;
}

.bod-menu .dropdown-toggle:hover {
  color: #fff;
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-decoration-color: #fff;
}

.bod-menu.dropdown .dropdown-menu {
  margin: 0 !important;
  padding: 0 1em;
}
.bod-menu .dropdown-item {
  margin: 1em 0 !important;
  padding: 0 !important;
}

.bod-img {
  border-radius: 9999px;
  width: 110px;
  height: 110px;
  object-fit: cover;
}

.link-button {
  border: none;
  background: transparent;
  transition: all 0.3s ease;
}

.bods_dropdown {
  position: relative;
}

.bods_dropdown-menu {
  display: block;
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  width: 100%;
  border-radius: 0.5rem;
  z-index: 1;
  padding: 1em 0;
}

.bods_dropdown-menu a {
  color: #424242;
  padding: 0.35em 1em;
  display: block;
  transition: all 0.3s ease;
}

.bods_dropdown-menu a:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Breadcrumb */
.breadcrumb {
  box-shadow: none;
  margin: 1em 0 0;
  justify-content: center;
}

.breadcrumb-item > a {
  font-size: 2.15rem;
  line-height: 2.5rem;
  color: #000;
  font-weight: 500;
  transition: all 0.3s ease;
}

.aboutuspage .breadcrumb-item > a,
.aboutuspage .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.aboutuspage .breadcrumb-item > a:hover {
  color: rgb(186, 186, 186);
}

.breadcrumb-item > a:hover {
  color: #023d54;
}

.breadcrumb-item,
.breadcrumb-item.active {
  font-size: 2.15rem;
  line-height: 2.5rem;
  font-weight: 500;
}

.breadcrumb-item.active {
  color: #6b7280;
}

/************* home page css start  ************/
section#homebanners {
  background-color: #1c3a27;
  padding: 50px 0px;
}
.homebanner {
  padding-right: 50px;
}
.homebanner h1 {
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0.5px;
  color: #fff;
  text-align: left;
  font-weight: 700;
}

.homebanner p {
  color: #fff;

  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 20px;
  text-align: left;
}

.bannerbtns {
  display: flex;
  width: 100%;
  margin-top: 50px;
}

div#btnGroupAddon {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 25px;
}

button.howitworks {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 500;
}

button.contactsus {
  background: #023d54;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid #023d54;
  font-size: 18px;
  font-weight: 500;

  width: 50%;
  margin-right: 30px;
}

.bannerbtns .input-group {
  align-items: center;
}

.text-primary-green {
  color: rgb(4, 114, 51) !important;
}

.btn-primary {
  background: white;
  border: 1px solid rgb(4, 114, 51);
  box-shadow: none;
  color: rgb(4, 114, 51);
  transition: all 0.3s ease;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: rgb(4, 114, 51);
  border-color: rgb(4, 114, 51);
  color: white;
}

/*********************** home page about us section ***********************/

.aboutusbox {
  margin: 2em 0;
}
.aboutus {
  text-align: center;
  padding: 0px 70px;
}

.dropdown {
  position: unset;
}

.dropdown-menu.show {
  min-width: 100%;
  padding: 1em;
}

.dropdown-header {
  font-size: 1.5rem !important;
  font-weight: 700;
  color: #1c3a27 !important;
}

.dropdown-item,
.dropdown-item .nav-link {
  margin: 0 !important;
}

.dropdown-item {
  padding: 0 1em !important;
  display: flex !important;
  align-items: center;
  gap: 1em;
  transition: all 0.3s ease;
}

.dropdown-item .nav-link {
  padding: 0.35em 0 !important;
}

.mega-menu .dropdown-divider {
  width: 100%;
  background-color: var(--bs-border-color-translucent);
}

.dropdown-item:active {
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}

.mega-menu .row {
  gap: 2em;
  flex-wrap: nowrap;
}

.mainmenu {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}
.dropdown-toggle::after {
  display: none;
}

@media screen and (max-width: 992px) {
  .mainmenu {
    background: transparent;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: rgba(255, 255, 255, 0.61) !important;
    background-color: transparent;
  }

  .dropdown-item:active {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 768px) {
  .dropdown-menu {
    padding: 1em;
  }

  .mega-menu .row {
    flex-wrap: wrap;
    gap: 0;
  }
}

.linesheading:before,
.linesheading:after {
  display: block;
  height: 3.5px;
  content: "";
  background-color: #023d54;
  width: 35px;
}

.linesheading {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #023d54;
  text-transform: capitalize;
}

.aboutus h2 {
  font-weight: 700;
  font-size: 30px;
  color: #1c3a27;
}

.aboutus p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  color: #000;
}

/* home page our mission section css */
.ourmission {
  padding-left: 50px;
}
.ourmission h2 {
  font-weight: 700;
  font-size: 30px;
  color: #1c3a27;
  padding: 0.25em 0;
}

.ourmission p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  color: #000;
  text-align: justify;
  margin: 0;
}

.leftlineheading {
  position: relative;
  color: #023d54;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.leftlineheading:before {
  display: block;
  content: "";
  height: 3.5px;
  background-color: #023d54;
  width: 30px;
  left: 0px;
  top: 50%;
}
/* home page tabs and slider css start here */
.bggrayy {
  padding: 50px 0px;
  background-color: #f1f1f1;
}

.bggrayy .nav-link {
  border-bottom: 0px !important;
  padding: 1rem;
  margin: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-bottom: 2px solid transparent !important;
  border-radius: 1000px;
}

.bggrayy .nav-link.active {
  border: 2px solid #023d54;
  border-bottom: 2px solid #023d54 !important;
}

section.bggrayy .nav.nav-tabs .nav-link.active span {
  color: #1c3a27;
  font-weight: 600;
}

section.bggrayy .nav.nav-tabs .nav-link.active i {
  color: #023d54;
  font-size: 18px;
}
section.bggrayy .nav-link i {
  transition: 0.6s ease all;
  margin-left: 10px;
}
section.bggrayy .nav-link span {
  transition: 0.6s ease all;
  font-size: 20px;
  font-weight: 400;

  color: #000;
}

section.bggrayy button.slick-arrow.slick-next {
  position: absolute;
  content: "";
  left: 0;
}

section.bggrayy button.slick-arrow.slick-prev {
  position: absolute;
  content: "";
  left: 0;
}
.tabsheadingsmove {
  margin-right: 20px;
}

#v-tabs-tab {
  border-bottom: 0px;
}

.unitmain-box {
  min-height: 520px;
  margin: 0px 10px;
}

.unitmain-box h5 {
  font-size: 20px;
  color: #000;
}

.unitmain-box h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 15px 0px;
  color: #1c3a27;
}
.unitmain-box p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #000;
}

.unitbtnss a {
  display: inline-block;
  background-color: #023d54;
  padding: 10px 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.unitbtnss a:hover {
  background-color: rgba(2, 61, 84, 0.772);
}

@media screen and (max-width: 991px) {
  .bggrayy .nav.nav-tabs .nav-link i {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 800px) {
  .business-slider .slick-arrow {
    display: none !important;
  }

  section.bggrayy .lefttabsss {
    margin-top: 0px;
  }
}

section.bggrayy button.slick-arrow.slick-next::before {
  position: absolute;
  top: 200px;
  left: -50px;
  color: #023d54;
  font-size: 40px;
}
section.bggrayy button.slick-arrow.slick-prev::before {
  position: absolute;
  font-size: 40px;
  left: -100px;
  top: 200px;
  color: #023d54;
}

/* end tabs and slider css */

/* tab section css  */
.bd-example,
.tab-content {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.slider h3 {
  background: #000;
  color: #3498db;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

/* new section css start  */
section.ournewss {
  margin-bottom: 50px;
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 0.5em;
}

.image .img-fluid {
  width: 100%;
  height: 820px;
}

.image img.rounded {
  margin-bottom: 15px;
  width: 100%;
}

.image .overlay {
  position: relative;
  height: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1em 1.5em;
  color: white;
  z-index: 5;
  background-color: #1c3a27;
}

.image .overlay::before {
  content: "";
  background: #023d54;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: -100%;
  z-index: -2;
  transition: all 0.3s ease-out;
}

.overlay .news-link {
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.overlay .news-link:hover {
  color: #1c3a27;
}

.overlay .news-title {
  font-size: 1.35rem;
}

/* .image:hover .overlay {
  color: black;
} */
.overlay h3 {
  font-size: 20px;
  font-weight: 500;
}
.container .image:hover .overlay .amount,
.container .image:hover .overlay .h4,
.container .image:hover .overlay .textmuted,
.container .image:hover .overlay .fa-star,
.container .image:hover .overlay .fa-star-half-alt,
.container .image:hover .overlay .review {
  color: black;
}

.image:hover .overlay::before {
  bottom: 0px;
}

.amount {
  font-size: 15px;
}
.textmuted {
  color: #f8f8f8;
  font-weight: 200;
  font-size: 15px;
  text-transform: capitalize;
}
.fa-star,
.fa-star-half-alt {
  color: white;
  font-size: 10px;
  margin: 0 1px;
}
.review {
  font-size: 12px;
  font-weight: 200;
}

.homeslider-logo {
  z-index: 999;
  position: relative;
  width: 60%;
  margin: -4.25% auto 0;
}

.homeslider-logo .slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.logo-card {
  /* background: #fff; */
  /* border: 1px solid #023d54; */
  /* border-radius: 1rem; */
  /* padding: 1rem; */
  height: 100px;
  width: 100px !important;
}

@media screen and (max-width: 768px) {
  .homeslider-logo {
    width: 85%;
    margin: calc(-5% - 10px) auto 0;
  }

  .logo-card {
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .homeslider-logo {
    margin: calc(-10% - 10px) auto 0;
  }
}

/* about us page css start here */

section#AboutUsBox {
  background-color: #e0e0e0;
  padding: 100px 0px;
}
section.yeasrbrands button.slick-arrow.slick-prev {
  position: absolute;
  content: "";
  right: 30px !important;
  top: 100px;
  left: auto;
}

section.yeasrbrands button.slick-arrow.slick-next {
  top: 100px;
  right: -12px;
}

section.yeasrbrands button.slick-arrow.slick-prev::before {
  color: #023d54;
  font-size: 40px;
}
section.yeasrbrands button.slick-arrow.slick-next::before {
  color: #023d54;
  font-size: 40px;
}
.aboutuspage h1,
.service-title {
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: 0.5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.headerpage h1,
.headerpage h2 {
  font-size: 3.75rem;
  line-height: 4rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
  max-width: 850px;
  color: #fff;
  width: 100%;
  margin: 0 auto;
}

.headerpage p {
  max-width: 750px;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1em auto;
}

.aboutuspage h1 span {
  color: #fff;
  font-size: 2.5rem;
  line-height: 3rem;
}

.service-title {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

.service-title span {
  color: #ebeef2;
  font-size: 2.5rem;
  line-height: 3rem;
}

.header {
  min-height: 85vh;
  height: 100%;
}

.header__background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoomIn 15s ease-in-out infinite alternate;
}

.headerpage {
  position: relative;
  padding: 1em;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 768px) {
  .headerpage h1,
  .headerpage h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 525px) {
  .headerpage h1,
  .headerpage h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.aboutuspage h5 {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #000;
  font-weight: 500;
}

.service-subtitle {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #ebeef2;
  font-weight: 500;
}

.aboutuspage h5 span,
.service-subtitle span {
  color: #023d54;

  font-size: 30px;
  line-height: 35px;
}

img.rounded.sizesss {
  max-width: 100%;
}

.Corporate input.form-control {
  outline: none;
  padding: 14px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border: 1px solid black;
}

.Corporate input.form-control:focus {
  box-shadow: none;
}
button.download {
  background-color: #023d54;
  border: 1px solid #023d54;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  width: 45%;
  color: #fff;

  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .service-title {
    max-width: 100%;
  }
}

/* aboutus page tab and slider css */
section.yeasrbrands ul#ex1 {
  background-color: #1c3a27;

  padding: 25px 20px 40px 20px;
  margin-bottom: 0px !important;
}

section.yeasrbrands ul#ex1 li.nav-item a {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}

section.yeasrbrands ul#ex1 li.nav-item .nav-link.active {
  background: #023d54;
}

section.yeasrbrands ul#ex1 li.nav-item a i {
  margin-right: 10px;
}
.tabssliders .tab-content {
  background-color: #ebeef2;
  padding: 50px;
}

.headingwithyear {
  display: flex;
  gap: 1em;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 20px;
}

.headingwithyear h3 {
  color: #1c3a27;

  font-size: 25px;
  font-weight: 600;
}

.headingwithyear h5 {
  color: #1c3a27;

  font-size: 20px;
  font-weight: 600;
}

.mainoutertabsscontent p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.fourboxmain {
  background: #00235a14;
  text-align: center;
  padding: 50px 30px;
  height: 270px;
  border-radius: 12px;
  transition: 0.8s ease all;
}
.fourboxmain:hover {
  background-color: #023d54;
  transform: translateY(-10px);
}

.fourboxmain:hover .fourinfo h3 {
  color: #fff;
}
.fourboxmain:hover .fourinfo p {
  color: #fff;
}

.fourinfo {
  margin-top: 20px;
}

.fourinfo h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #252a34;
}

.fourinfo p {
  color: #1c3a27;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.threemainbox {
  text-align: center;
  background-color: #f8f8f8;
}
.threeinfo {
  padding: 30px;
}
.threeinfo h3 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;

  color: #000;
}

.threeinfo p {
  font-size: 16px;
  font-weight: 400;
  color: #1c1d1f;
}

.threemainbox:hover {
  background-color: #1c3a27;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.threemainbox:hover .threeinfo h3 {
  color: #fff;
}

.threemainbox:hover .threeinfo p {
  color: #fff;
}
/* footer css start here */
.footer__logo_container {
  min-height: 100%;
  padding: 5rem;
  background: #023d54;
}

.footer__link_container {
  padding: 5rem;
  min-height: 100%;
  background: url("./assets/world-map.png") no-repeat;
  background-size: cover;
  background-color: rgb(2, 61, 84);
  position: relative;
}

.footer-copyright {
  background-color: #002c3e;
  color: #fff;
}

.footer-copyright p {
  max-width: 768px;
  margin: 0 auto;
}

.footer__link_container::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 61, 84, 0.881);
}

.getintouch h3 {
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.footer-link-title,
.business_unit h4 {
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 1.75rem;
  position: relative;
}

.footer-link-title::after {
  content: "";
  display: block;
  width: 75%;
  border-radius: 9999px;
  height: 0.2rem;
  background: #fff;
}

.footer__links {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em;
  margin-top: 1em;
}

.business_unit h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5em;
}

.footer__links a,
.footer__contact-links a {
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.footer__links a:hover,
.footer__contact-links a:hover {
  opacity: 0.65;
}

.footer__contact-links ul {
  margin-top: 1em;
}

.footer__contact-links li {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #fff;
  font-size: 1.25rem;
}

.footer__contact-links li:not(:last-child),
.business_unit li:not(:last-child) {
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .footer__link_container,
  .footer__logo_container {
    padding: 3rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .footer__links {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* contact us page css */
.connectingalways {
  padding-top: 60px;
}

.connectingalways .container .row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-contact {
  margin: 3em 0;
}

.team-contact h2 {
  font-size: 2.15rem;
  font-weight: 700;
  color: #1c3a27;
}

.team-contact-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.5em;
  margin-top: 1.5em;
}

.contact-info-card.shadowww-box {
  padding: 2em;
  background-color: #fff;
  transition: all 0.5s ease-in;
  cursor: pointer;
}

.contact-info-card.shadowww-box button {
  cursor: text;
}

.contact-info-card.shadowww-box a:hover {
  text-decoration: underline;
}

.contact-info-card.shadowww-box:hover .borderr-box {
  border-color: #fff;
}

.contact-info-card.shadowww-box:hover {
  background-color: #1c3a27;
  box-shadow: 0 5px 12px 0px rgb(0, 0, 0);
}

.contact-info-card.shadowww-box:hover h3,
.contact-info-card.shadowww-box:hover a,
.contact-info-card.shadowww-box:hover strong,
.contact-info-card.shadowww-box:hover .link-button {
  color: #fff;
}
.contact-info-card.shadowww-box:hover span,
.contact-info-card.shadowww-box:hover svg {
  background: #fff;
  color: #1c3a27;
}

.contact-info-card h3 {
  color: #1c3a27;
  font-size: 1.5rem;
  transition: all 0.5s ease-in;
}

.info-card-list-item a {
  word-break: break-word;
  font-size: 1.25rem;
  color: #1c3a27;
  transition: all 0.5s ease-in;
}

.info-card-list-item a:hover {
  color: #023d54;
}

.info-card-list-item strong {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #023d54;
  font-weight: 600;
  transition: all 0.5s ease-in;
}

.info-card-list-item svg {
  font-size: 1.75rem;
  color: #fff;
}

.info-card-list-item span {
  background: #1c3a27;
  padding: 1em;
  border-radius: 100%;
}

.info-card-list-item .link-button {
  transition: all 0.3s ease;
}

.info-card-list-item .link-button:hover {
  color: #023d54;
}

.threeboxxx {
  position: relative;
  margin-bottom: -100px;
}

@media screen and (max-width: 800px) {
  .team-contact-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .threeboxxx {
    margin: 1em 0;
  }
}

@media screen and (max-width: 600px) {
  .team-contact-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.icon-box {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #1c3a27;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-box i {
  color: #fff;
  font-size: 25px;
}

.icon-info h5 {
  color: #023d54;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.icon-info ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.icon-info ul li a {
  color: #1c3a27;
  transition: all 0.3s ease;
}

.icon-info ul li a:hover,
.icon-info .link-button:hover {
  color: #023d54;
  text-decoration: underline;
}

.row.inlinebox {
  display: flex;
  align-items: flex-start;
}

.borderr-box h3 {
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.borderr-box {
  padding: 10px 0px;
}
.shadowww-box {
  box-shadow: 0px 5px 12px -5px gray;
  padding: 30px;
}

.shadowww-box {
  box-shadow: 0px 5px 12px -5px gray;
  padding: 30px;
  border: 2px solid #1c3a27;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.shadowww-box:hover {
  box-shadow: 0 5px 12px 0px rgba(0, 0, 0, 0.5);
}

.borderr-box {
  border-bottom: 1px solid #7ba3c1;
}

.shadowww-box .input-group.mb-3 {
  border-bottom: 1px solid #5f5e5e;
  padding: 1px 0px;
}

.shadowww-box .input-group.mb-3 input.form-control {
  border: none;
}

.shadowww-box .input-group.mb-3 span#basic-addon1 {
  border: none;
  background-color: transparent;
}

.sendmessage {
  background-color: #023d54;
  color: white;
  padding: 0.65rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1em auto 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sendmessage:hover {
  background-color: #1c3a27;
}

.sendmessage:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.shadowww-box .input-group.mb-3 span#basic-addon1 i {
  color: #023d54;
  font-size: 20px;
}

.shadowww-box .input-group.mb-3 input.form-control::placeholder {
  color: #1c3a27;
}
.fourboxmaingreen {
  background-color: #fff;
  padding: 30px 50px;
  text-align: center;
}

.fourimgsbox svg {
  color: #023d54;
}

.container.bggg {
  background-color: #1c3a27;
  padding: 30px;
  border-radius: 12px;
}
.threeboxxx .fourboxmaingreen {
  height: 270px;
}
.fourinfogreen {
  text-align: center;
  margin-top: 16px;
}
.fourinfogreen h3 {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;

  color: #252a34;
}

.fourinfogreen p {
  color: #1c3a27;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.spaceupdowngray {
  padding: 0.5em 0;
  /* background-color: #eeeeee; */
}

.trsutimages .img-fluid {
  margin: 0 auto !important;
  max-width: 100px;
}

.logo-slider .slick-track {
  display: flex;
  align-items: center;
  gap: 2em;
}

.block {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.bordered-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid #023d54;
  width: fit-content;
  background-color: #fff;
  padding: 0.25em;
  margin-bottom: -10%;
  border-radius: 9999px;
  z-index: 20;
}

.hovicon {
  display: inline-block;
  font-size: 45px;
  line-height: 90px;
  cursor: pointer;
  margin: 20px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
}
.hovicon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.hovicon:before {
  font-size: 48px;
  line-height: 90px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.hovicon.effect-4 {
  width: 130px;
  height: 130px;
  padding: 8px;
  box-shadow: 0 0 0 5px #1c3a27;
}

.hovicon.effect-4:before {
  line-height: 92px;
}
.hovicon.effect-4::after {
  top: -4px;
  left: -4px;
  padding: 0;
  z-index: 10;
  border: 4px dashed #1c3a27;
}

.sub-b {
  cursor: default;
}

/* Effect 1 */
.hovicon.effect-1 {
  background: rgba(255, 255, 255, 0.1);
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
}
.hovicon.effect-1:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 4px #fff;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}
.hovicon.effect-1 {
  background: rgba(255, 255, 255, 0.1);
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
}
.hovicon.effect-1:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 4px #fff;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}
/* Effect 1a */
.hovicon.effect-1.sub-a:hover {
  background: rgba(255, 255, 255, 1);
  color: #41ab6b;
}
.hovicon.effect-1.sub-a:hover i {
  color: #41ab6b;
}
.hovicon.effect-1.sub-a:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.adminbox {
  padding: 50px 0 30px 0;
  background-color: #f3f3f3;
  text-align: center;
  /* margin-top: -40px; */
  border-radius: 12px;
  /* height: 100%; */
}

.card-col {
  max-width: 450px !important;
}

.adminbox h3 {
  background-color: #023d54;
  color: #fff;
  margin-top: 20px;

  font-size: 20px;
  padding: 5px;
}

.adminbox h5 {
  font-size: 18px;
  font-weight: 700;
  color: #1c3a27;
  padding: 0.5rem 0.25rem 0;
}

.adminbox p {
  font-size: 18px;
  font-weight: 400;
  color: #1c3a27;
  padding: 0 0.25rem;
}

.card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}

.resource-card,
.news-card {
  background: none;
  cursor: initial;
}

.resource-slider .slick-slide {
  min-height: 100%;
}

.resource-slider .slick-track {
  display: flex;
  gap: 1rem;
  height: fit-content;
}

.resource-slider-controls {
  position: absolute;
  bottom: 15%;
  left: calc(2% - 5px);
}

@media screen and (max-width: 1200px) {
  .resource-slider-controls {
    position: static;
  }
}

.custom-controls .custom-prev-icon,
.custom-controls .custom-next-icon {
  color: rgb(255, 255, 255);
  background: rgb(4, 114, 51);
  border-radius: 100%;
  padding: 0.5rem;
  font-size: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-controls .custom-prev-icon:hover,
.custom-controls .custom-next-icon:hover {
  background: rgb(0, 144, 10);
}

.icon-wrapper {
  background-color: #023d54;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.card:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.3);
}

section.spaceupdown .card {
  border: none;
  text-align: center;
}

section.spaceupdown .card h3 {
  font-size: 25px;

  font-weight: 600;
  margin: 20px 0px;
  text-transform: capitalize;
}

section.spaceupdown .card p {
  font-size: 16px;
  font-weight: 400;
}

.formbox {
  background-color: green;
  background: #023d54;
  padding: 25px;
  border-radius: 14px;
}

.formbox input.form-control {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  border-radius: 0px;
  margin: 15px 0px;
}

.formbox input.form-control::placeholder {
  color: #fff;
}

.unit__contact {
  background: #023d54;
  padding: 2rem 1rem;
  margin: 0 auto;
  color: #fff;
  margin-bottom: 1.5em;
}

.unit__contact h2 {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  position: relative;
  display: inline-block;
}

.unit__contact h2::after {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 1px;
  display: block;
}

.servicebottommenulinks {
  padding: 0px;
  margin: 1em 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.servicebottommenulinks li {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.servicebottommenulinks li svg,
.servicebottommenulinks li a,
.servicebottommenulinks .link-button {
  color: #ffffff;
}

.servicebottommenulinks li a,
.servicebottommenulinks .link-button {
  font-size: 1.25rem;
  transition: all 0.3s ease;
}

.servicebottommenulinks li a:hover,
.servicebottommenulinks .link-button:hover {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
}

.threeholder {
  position: relative;
  margin: 0 auto;
  height: 380px;
  width: 100%;
}

.threeone {
  height: 100%;
  background: rgba(235, 238, 242, 1);
  text-align: center;
  transition: all 0.8s ease;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}

.counter-box {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2em;
}

@media screen and (max-width: 1200px) {
  .counter-box {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .counter-box {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.ciclre-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ciclre-info h3 {
  font-size: 2rem;
  font-weight: 700;
}

.ciclre-info p {
  font-size: 16px;
}

.circleborder {
  margin: 0 auto;
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 10px solid #023d54;
}

.lg-react-element {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2em;
  margin-bottom: 2em;
}

.gallery-thumbnail {
  object-fit: cover;
  height: 450px;
  max-width: fit-content;
  margin: 0 auto;
}

@media screen and (max-width: 1025px) {
  .lg-react-element {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 550px) {
  .lg-react-element {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .gallery-thumbnail {
    max-width: 100%;
  }
}

.service-card,
.partner-card {
  min-height: 100%;
  cursor: default;
}

.partner-card h3,
.service-card h3 {
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
}

.gallery-card {
  cursor: pointer;
}

.partners-icon {
  width: 50px;
  height: 50px;
}

.service-card img {
  height: 300px;
  object-fit: cover;
}

.service-box {
  height: 120px;
  transition: all 0.3s;
  padding: 10px 5px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  box-shadow: 10px 10px 15px rgba(60, 40, 40, 0.15);
  position: relative;
}

.service-box:nth-child(odd) {
  color: #1c3a27;
}

.service-box:nth-child(even) {
  color: rgb(0, 144, 10);
}

.service-box::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 5px;
  height: 100%;
  background: #023d54;
  transition: all 0.4s;
}

.service-box:hover {
  box-shadow: 10px 10px 15px rgba(60, 40, 40, 0.25);
  transform: scale(1.04);
}

.service-box:hover .title-area span {
  color: #fff;
}

.service-box:hover::before {
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.content {
  position: relative;
  display: flex;
}

.icons {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: 0.5s ease-in-out;
}

.service-box:hover .icons {
  color: #fff;
}

.title-area h3 {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 25px;
}

.title-area span {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.service-box:hover h3,
.service-box:hover p {
  color: #fff;
}

.contact-img img {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

@media screen and (max-width: 1300px) {
  .navbar-nav a {
    font-size: 15px;
  }
  section.bggrayy .nav-link span {
    font-size: 16px;
  }
}

@media screen and (max-width: 1280px) {
  section.bggrayy .nav-link span {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .navbar-collapse {
    background: rgb(28, 58, 39);
    padding: 2em 0;
  }
}

@media screen and (max-width: 768px) {
  .contact-img {
    width: auto;
  }
  .infotopmenulinks ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .topmenusociallinks ul {
    justify-content: center;
  }
  .homebanner h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .bannerbtns {
    flex-direction: column;
  }
  button.contactsus {
    width: 100%;
  }
  .bannerbtns .input-group {
    justify-content: center;
    margin-bottom: 30px;
  }
  .aboutus {
    padding: 0px 10px;
  }
  .aboutus h2 {
    font-size: 25px;
  }

  .ourmission {
    padding-right: 10px;
    padding-left: 10px;
  }
  .ourmission h2 {
    font-size: 25px;
  }
  section.spaceupdown .row.d-flex.justify-content-start.align-items-center {
    flex-direction: column-reverse;
  }
  section.bggrayy .nav-link span {
    font-size: 16px;
  }

  .fourboxmain {
    margin-bottom: 15px;
  }
  section.connectingalways {
    padding-top: 0px;
  }
  .alwaybox {
    margin: 30px;
  }
  .fourboxmaingreen {
    margin-bottom: 20px;
  }
}

.slick-arrow {
  z-index: 10;
  cursor: pointer;
}

.custom-arrow.slick-prev {
  left: calc(5% - 25px);
}

.custom-arrow.slick-next {
  right: calc(5% - 25px);
}

.custom-arrow.slick-prev::before,
.custom-arrow.slick-next:before {
  color: #023d54;
  font-size: 2.5rem;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-arrow.slick-next:hover::before,
.custom-arrow.slick-prev:hover::before {
  color: rgba(179, 131, 42, 0.74);
}

@media screen and (max-width: 768px) {
  .custom-arrow.slick-arrow {
    display: none;
  }
}

.centered-slider .slick-slide {
  height: 250px;
  width: 250px;
}

.centered-slider .slick-slide > div {
  height: 100%;
  width: 100%;
  padding: 0 0.5em;
}

.card-bg-yellow {
  background-color: rgba(179, 132, 42, 1);
}

.card-bg-green {
  background-color: rgba(28, 58, 39, 1);
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button::before {
  font-size: 1rem;
  color: #1c3a27;
  transition: all.3s ease;
}

.slick-dots li.slick-active button::before {
  color: #1c3a27;
}

.news-slide {
  padding: 0 0.5em;
}

.newsinfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .news-slide {
    padding: 0;
  }
}

/* About Timeline */
.vertical-timeline-element-content {
  background-color: #ebeef2 !important;
}

.vertical-timeline::before {
  background: #ebeef2 !important;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid #ebeef2 !important;
}

.vertical__timeline_element-title {
  font-size: 1.85rem;
  color: #1c3a27;
  line-height: 2.5rem;
}

.vertical__timeline_element-subtitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1c3a27;
}

.vertical__timeline_element-text {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
}

.vertical__timeline_element-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.vertical-timeline-element-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.vertical-timeline-element-icon i {
  font-size: 2rem;
}

@media screen and (max-width: 800px) {
  .vertical__timeline_element-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .vertical__timeline_element-subtitle {
    font-size: 1.1rem;
  }

  .vertical-timeline-element-icon i {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.loadingSpinnerContainer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border-top: 4px solid;
  border-color: #023d54;
  border-radius: 100%;
  animation: spin 1s linear infinite;
}

.loadingSpinnerContainer span {
  color: #fff;
  padding-left: 1rem;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.md-message-pre {
  text-wrap: wrap;
  font-size: 1.25rem;
}

.max-w-lg {
  max-width: 650px;
  width: 100%;
}

@media screen and (max-width: 1025px) {
  .max-w-lg {
    max-width: 100%;
  }
}

.gallery-card img,
.gallery-card,
.gallery-thumbnail {
  transition: transform 0.3s ease;
}

.gallery-card h3 {
  color: #1c3a27;
}

.gallery-card:hover,
.gallery-thumbnail:hover,
.gallery-card:hover img {
  transform: scale(1.01);
}
/* 
.react-pdf__Document {
  display: inline-block;
}

.react-pdf__Page {
  height: 100%;
  width: 550px;
  max-height: 720px;
} */

.react-pdf__Page__annotations,
.endOfContent {
  display: none;
}
